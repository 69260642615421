import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { withTranslation } from "react-i18next"

const QualityPolicyPage = ({ t, i18n }) => (
  <Layout>
    <SEO title="Polityka jakości" />
    <section className="section" style={{ marginTop: "6rem" }}>
      <div className="container">
        <p className="title is-1 is-family-secondary has-text-primary">
          {t("QUALITY_POLICY")}
        </p>
        <div
          className="columns"
          style={{ paddingBottom: "3rem", borderBottom: "1px solid #E30C5C" }}
        >
          <div className="column">
            <div
              className="content is-flex"
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <p>
                {t("ECONOMIC_POLICY")} <strong>FASTIGO LOGISTICS</strong>{" "}
                {t("ECONOMIC_POLICY_ABOUT")}
              </p>

              <p>
                <span className="has-text-primary has-text-weight-bold is-marginless">
                  {t("LOGISTIC")}
                </span>
                <br />
                {t("LOGISTIC_ABOUT")}{" "}
                {t("QUALITY_MANAGEMENT")}
              </p>
              <p></p>
              <p>{t("DATE")} </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p
                className="title is-3 is-family-secondary"
                style={{ marginBottom: "0.5rem" }}
              >
                {t("MAIN_GOALS")}{" "}
              </p>
              <ul style={{ marginTop: "0" }}>
                <li>{t("MAIN_GOALS_1")}</li>
                <li>{t("MAIN_GOALS_2")}</li>
                <li>{t("MAIN_GOALS_3")}</li>
                <li>{t("MAIN_GOALS_4")}</li>
              </ul>
              <p
                className="title is-3 is-family-secondary"
                style={{ marginBottom: "0.5rem" }}
              >
                {t("OWNER_DECLARES")}
              </p>
              <ul style={{ marginTop: "0" }}>
                <li>{t("OWNER_DECLARES_1")}</li>
                <li>{t("OWNER_DECLARES_2")}</li>
                <li>{t("OWNER_DECLARES_3")}</li>
                <li>{t("OWNER_DECLARES_4")}</li>
              </ul>
            </div>
          </div>
        </div>
        <p
          className="title is-1 is-family-secondary"
          style={{ paddingTop: "3rem" }}
        >
          {t("CERTIFICATE")}
        </p>
      </div>
    </section>
  </Layout>
)

export default withTranslation("QualityPolicyPage")(QualityPolicyPage)
